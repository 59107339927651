<template>
  <div v-if="validToken">
    <h1 class="display-4">{{ $t('ALUMNI_CCID_TITLE') }}</h1>
    <p>{{ $t('ALUMNI_CCID_DESCRIPTION') }}</p>
    <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>
    <form @submit.prevent="submit()" class="d-flex flex-column">
      <ValidatedTextField ref="first_name" name="first_name" :title="$t('LABEL_FIRST_NAME')" v-model="first_name" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="last_name" name="last_name" :title="$t('LABEL_LAST_NAME')" v-model="last_name" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="current_phone" name="current_phone" :title="$t('LABEL_CURRENT_PHONE')" v-model="current_phone" :validations="['sms']" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="current_email" name="current_email" :title="$t('LABEL_CURRENT_EMAIL')" v-model="current_email" :validations="['email']" hideCheckmark :label-col=12 />
      <ValidatedTextField ref="previous_legal_names" name="previous_legal_names" :title="$t('LABEL_PREVIOUS_LEGAL_NAMES')" v-model="previous_legal_names" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previous_phones" name="previous_phones" :title="$t('LABEL_PREVIOUS_PHONES')" v-model="previous_phones" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previous_emails" name="previous_emails" :title="$t('LABEL_PREVIOUS_EMAILS')" v-model="previous_emails" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="previous_addresses" name="previous_addresses" :title="$t('LABEL_PREVIOUS_ADDRESSES')" v-model="previous_addresses" hideCheckmark optional :label-col=12 />
      <ValidatedTextField ref="degrees" name="degrees" :title="$t('LABEL_DEGREES')" v-model="degrees" hideCheckmark :label-col=12 />
      <button class="btn btn-primary align-self-end" ref="submit" name="submit" type="submit">{{ $t('BUTTON_SUBMIT') }}</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import ValidatedTextField from './ValidatedTextField.vue';
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  inject: ['queryParams'],
  data() {
    return {
      validToken: false,
      token: '',
      first_name: '',
      last_name: '',
      current_phone: '',
      current_email: '',
      previous_legal_names: '',
      previous_phones: '',
      previous_emails: '',
      previous_addresses: '',
      degrees: '',
    }
  },
  mounted: async function() {
    this.token = this.queryParams.get('token')

    await this.validateToken();
  },
  components: {
    ValidatedTextField,
  },
  methods: {
    async validateToken() {
      this.validToken = await axios.get('/alumniccid/validatetoken', { params: { "token": this.token }})
        .then(() => true)
        .catch(() => false);
    },
    async validateFields() {
      return await this.$refs.first_name.validate()
        && await this.$refs.last_name.validate()
        && await this.$refs.current_phone.validate()
        && await this.$refs.current_email.validate()
        && await this.$refs.previous_legal_names.validate()
        && await this.$refs.previous_phones.validate()
        && await this.$refs.previous_emails.validate()
        && await this.$refs.previous_addresses.validate()
        && await this.$refs.degrees.validate()
    },
    async submit() {
      if (!this.validateFields()) return;

      return await axios.post('/alumniccid/request', {
        token: this.token,
        first_name: this.first_name,
        last_name: this.last_name,
        current_phone: this.current_phone,
        current_email: this.current_email,
        previous_legal_names: this.previous_legal_names,
        previous_phones: this.previous_phones,
        previous_emails: this.previous_emails,
        previous_addresses: this.previous_addresses,
        degrees: this.degrees,
      })
      .then(() => {
        this.showMessage(this.$t('FORM_SUBMITTED'));
      })
      .catch((error) => {
        this.showError(error, this.$t('FORM_FAILED'));
      })
    },
  },
}
</script>
